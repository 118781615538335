/* style.css */

/* importing the site theme typography */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* style for the font -------------start */


/* End-------------------------- style for the font */
/* style for the body starts here -------------start */

body {
  font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background-color: #0e0f22;
    overflow-x: hidden;
}

h2{
  font-size: 2.9rem;
  font-weight: 600;
}

h3{
  font-size: 2rem;
  font-weight: 500;
}

h4{
  font-size: 1.5rem;
  font-weight: 400;
}

p{
  font-weight: 300;
}

/* Background Gradient blobs Moving style starts here -------------start */
.bouncing-blob {
  width: 32vw;
  aspect-ratio: 1;
  border-radius: 50%;
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform-origin: left top;
}

.bouncing-blob--blue {
  background: #102a48;
}

.bouncing-blob--violet {
  background: #190639;
  z-index: 2;
  width: 15vw;
}

.bouncing-blob--purple {
  background: #20213d;
}

.bouncing-blob--pink {
  background: #3f224350;
}

.bouncing-blobs-container {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bouncing-blobs-glass {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(140px);
  -webkit-backdrop-filter: blur(140px);
  pointer-events: none;
}

.bouncing-blobs {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Scroll bar -------------start */

::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #190639; /* Track color */
}

::-webkit-scrollbar-thumb {
  background: #3b2886; 
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4f3aa4; /* Thumb color on hover */
}

/* Section  -------------start */
.section {
  padding: 3rem 2rem; 
  margin: 0 auto;
  max-width: 1200px;
}

.description h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  line-height: 35px;
  font-weight: 500;
  color: #fff;
}

/* Section Title & Section Description -------------start */
.title {
  display: block;
  position: relative;
  width: fit-content;
  font-size: 1rem;
  padding: 10px 20px;
  font-weight: 400;
  background: #222349;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}

.section-desciption{
  color: #fff;
  text-align: center;
  margin-bottom: 2rem;
}

.section-desciption h2{
  font-size: 2.9rem;
  font-weight: 500;
}


/* Basic colors starts here -------------start */

.color-bright-1{
  color: #c1c1c1;
}

.color-bright-2{
  color: #968ad8;
}

.bg-1{
  background: linear-gradient(45deg, #131f41, #0e2032, #133045, #263f49, #394b4d);
}

.bg-2{
  background: linear-gradient(45deg, #2a1111, #4a2d1e, #6c4a28, #7b602b, #9c883a);
}

.bg-3{
  background: linear-gradient(45deg, #01372a, #013222, #033f27, #224428, #32482b);
}

.bg-4{
  background: linear-gradient(45deg, #230b3a, #2d1644, #352248, #362942, #453c50);
}

.bg-5{
  background: linear-gradient(45deg, #011036, #081639, #10214a, #1b2c59, #2e3f6a);
}

.bg-6{
  background: linear-gradient(45deg, #300e0e, #3f1818, #532828, #5c3434, #684646);

}

.bg-7{
  background: linear-gradient(45deg, #c149d5, #9C27B0, #9C27B0, #ba62c9, #9C27B0);

}
.bg-8{
  background: linear-gradient(45deg, #7366db, #5444d3, #6287d9, #8a7edd, #453c50);
}
.gradient-one{
  background: linear-gradient(45deg, #66b3ff, #6699ff, #6666ff, #6666b3, #666699, #66b3ff);
  background-clip: text;
  color: #0000;
}

/* Custom cursor -------------start */
.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  }

.custom-cursor {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  z-index: 900;
  top: -10px;
  left: -10px;
  pointer-events: none;
  display: none !important;
  }
  
  .cursor__small {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #66ffff;
  z-index: 5;
  margin: -15px 0 0 -15px;
  }
  
  .cursor__big {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border:  #66ffff 2px thin;
  box-shadow: 1px 1px 20px #66ffff;
  z-index: 1;
  opacity: 1;
  margin: -15px 0 0 -15px;
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  -moz-transition: all 320ms cubic-bezier(0.26, 0.01, 0.17, 1);
  -o-transition: all 320ms cubic-bezier(0.26, 0.01, 0.17, 1);
  -webkit-transition: all 320ms cubic-bezier(0.26, 0.01, 0.17, 1);
  transition: all 320ms cubic-bezier(0.26, 0.01, 0.17, 1);
  }
/* End-------------------------- style for the body */


/* style for the header area including the logo and menu starts here -------------start */
.header {
  background: #fff;
  padding: 1rem 0;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: background 0.3s;
}

.header.scrolled {
  background: #fff;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  
}
.logo img{
  height: 40px;
  width: auto;
}

.logo h2{
  font-size: 20px;
}

.mobile-logo{
  display: none;
}

/* Nav Bar -------------start */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.menu {
  display: flex;
}

.nav-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative; 
}

.nav-menu li {
  margin: 0 15px;
  position: relative; 
}

.nav-menu li a,.nav-menu li span {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
  cursor: pointer;
}

.nav-menu li a:hover,.nav-menu li span:hover {
  color: #66b3ff;
}

/* Main Menu and Submenu Styles */
.menu-item {
  position: relative;
}

.menu-item a,.menu-item span {
  text-decoration: none;
  color: #fff;
  color: #022010;
  padding: 15px;
  display: block;
}

/* Submenu styles */
.menu-item.has-submenu > a::after,.menu-item.has-submenu > span::after {
  content: "\25BC"; /* Unicode character for down arrow */
  margin-left: 10px;
  font-size: 12px;
  display: inline-block;
  transition: transform 0.3s ease;
}

.menu-item.has-submenu.active > a::after {
  transform: rotate(180deg);
}

.submenu {
display: none;
position: absolute;
top: 100%; 
left: 0;
background: #000;
list-style: none;
padding: 10px 0;
margin: 0;
border-radius: 5px;
overflow: hidden;
z-index: 1000; 
opacity: 0; /* Start hidden */
transition: opacity 0.3s ease, visibility 0.3s ease, left 0.3s ease;
visibility: hidden; /* Ensure it is hidden initially */
min-width: 280px;
overflow: auto;
/* height: 90vh; */
}



.submenu {
  pointer-events: none;
  color: rgba(0,0,0,0.5);
  font-weight: normal;
  padding: 1em;
  position: absolute;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  transform: translateY(-20px);
  transform-origin: 50% 0 0;
  opacity: 0;
  box-shadow: 0 2px 7px rgba(0,0,0,0.1), 0 2px 20px rgba(0,0,0,0.3);
  box-sizing: border-box;
  background: white;
  border-radius: 10px;
  overflow-y: auto;
}


.submenu li a {
  color: #1f1f29;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.submenu li a:hover {
  color: #66b3ff;
}

.menu-item.has-submenu > a::after,.menu-item.has-submenu > span::after {
  content: "\25BC"; /* Unicode character for down arrow */
  margin-left: 10px;
  font-size: 12px;
  display: inline-block;
  transition: transform 0.3s ease;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 1100;
  transition: transform 0.3s;
}

.menu-toggle span {
  height: 2px;
  width: 25px;
  background: #000;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: background 0.3s, transform 0.3s;
}

.menu-toggle.active span {
  background: red;
}

.menu-toggle.active span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-toggle.active span:nth-child(2) {
  opacity: 0;
}

.menu-toggle.active span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}
/* End-------------------------- style for the header area including the logo and menu */

/* style for effect of the social button starts here -------------start */
.effect a {
  text-decoration: none !important;
  width: 45px;
  height: 45px;
  display: flex;  
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 5px;
  font-size: 17px;
  overflow: hidden;
  position: relative;
  color: #fff;
  border: #2d3662 1px solid;
  }

  .effect a i {
  position: relative;
  z-index: 3;
  }

  .effect a:last-child {
  margin-right: 0px;
  }

  .effect a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3b2886;
  transition: .5s;
  z-index: 2;
  }

  .effect a:hover:before {
  top: 0;
  }

  .effect a i {
  display: inline-block;
  vertical-align: middle;
  }

  a .icon {
  transition: .5s;
  z-index: 3;
  }

  a:hover .icon {
  transform: rotateY(360deg);
  }


.effect.varrius a {
  transition: all 0.2s linear 0s;
  }
  .effect.varrius a:after {
  content: "";
  display: block;
  width: 90%;
  height: 90%;
  top: -110%;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  background-color:#007bff;
  border-radius: 50%;
  }

  .effect.varrius a:hover {
  color: #fff;
  border: solid 1px #3b2886;
  }

  .effect.varrius a:hover:after {
  top: 5%;
  transition: all 0.2s linear 0s;
  }

  .effect .buttons {
    display: flex;
  }

  .mobile-social{
    display: none;
  }
/* End-------------------------- effect of the social button */

/* style for the home page main banner  -------------start */
main{
  margin-top: 4rem;
}

.header-banner {
  width: 100%;
  /* height: 100vh; */
  overflow: hidden;
}

#header-slider .item {
  /* height: 100vh; */
  background-size: cover;
  background-position: center;
  position: relative;
  height: calc(100vh - 150px);
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  position: absolute;
  /* bottom: 20%; */
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
  width: 70%;
  z-index: 1;
  top: 50%;
  transform: translate(-50%, -50%);
}

.content h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 5cqi;
  letter-spacing: -2px;
  background: linear-gradient(45deg, #ff6666, #ffb366, #ffff66, #b3ff66, #66ff66, #66ffb3, #66ffff, #66b3ff, #6666ff, #b366ff, #ff66ff, #ff66b3, #ff6666);
  background: linear-gradient(in hsl longer hue 40deg, #f66 0 0);
  background-clip: text;
  color: #0000;
  margin: 0;
  line-height: 5cqi;
}

.content h1 span{
  color: #fff;
}
.content p {
  font-size: 1.5rem;
  margin: 0.5rem 0 0;
}


/* Animations */
@keyframes fadeup {
  0% { transform: translateY(20px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

@keyframes bouncein {
  0%, 20%, 40%, 60%, 80%, 100% {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% { opacity: 0; transform: translate3d(0, -3000px, 0); }
  60% { opacity: 1; transform: translate3d(0, 25px, 0); }
  75% { transform: translate3d(0, -10px, 0); }
  90% { transform: translate3d(0, 5px, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

.animate-fadeup {
  animation: fadeup 1.5s ease-out;
}

.animate-bouncein {
  animation: bouncein 1.5s;
  animation-delay: 0.2s;
}

.owl-theme .owl-controls .owl-buttons div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.owl-theme .owl-controls .owl-buttons .owl-prev {
  left: 0;
}

.owl-theme .owl-controls .owl-buttons .owl-next {
  right: 0;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Long Oval Button Styles for the banner section */
.long-oval {
  background: linear-gradient(90deg, #172250, #8869e8);
  color: #fff;
  border-radius: 50px;
  position: relative;
  margin: 2rem 1rem;
}

.long-oval .arrow-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  margin-left: 50px;
  transition: background 0.3s ease;
  right: 7px; 
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.lnk{
  margin-right: 60px;
}

.long-oval .arrow-circle svg {
  fill: #000;
}

.long-oval:hover .arrow-circle {
  background: #aa66ff;
}

.long-oval:hover .arrow-circle svg {
  fill: #fff;
}

.long-oval:hover {
  transform: scale(1.05);
}

.long-trans{
  border: #8869e8 1px solid;
  color: #fff;
  border-radius: 50px;
  position: relative;
  margin: 2rem 1rem;
}

.long-trans .arrow-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  margin-left: 50px; 
  transition: background 0.3s ease;
  right: 7px; 
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.long-trans:hover .arrow-circle {
  background: #aa66ff;
}

.btn:hover {
  transform: scale(1.05);
}

/* Rotating svg menu  -------------start */
.rotate {
  position: absolute;
  bottom: 20px; 
  right: 20px; 
  z-index: 2;
}

.rotate__svg {
  width: 100%;
  height: auto;
  transform-box: fill-box;
  fill: #262d4d;
  stroke: #666699;
  stroke-width: 0.05em;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.rotate__cloud {
  transform-origin: 50% 50%;
  -webkit-animation: rotate normal infinite 60s linear;
          animation: rotate normal infinite 60s linear;
  fill: rgba(255, 255, 255, 0.15);
}

.rotate__face, .rotate__arrow {
  transform-origin: 50% 50%;
  transition: transform 0.15s cubic-bezier(0.32, 0, 0.67, 0);
}

.rotate:hover .rotate__face, .rotate:hover .rotate__arrow {
  transform: scale(1.1);
  transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
}

.rotate__arrow {
  stroke-width: 0.075em;
}

.rotate__text {
  -webkit-animation: rotateReverse normal infinite 20s linear;
          animation: rotateReverse normal infinite 20s linear;
  transform-origin: 50% 50%;
}

.rotate:hover .rotate__text {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rotate--alt {
  font-size: 1.15rem;
  letter-spacing: 0;
  word-spacing: 0;
}

@-webkit-keyframes rotate {
  to {
      transform: rotate(360deg);
  }
}

@keyframes rotate {
  to {
      transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateReverse {
  to {
      transform: rotate(-360deg);
  }
}

@keyframes rotateReverse {
  to {
      transform: rotate(-360deg);
  }
}
/* End-------------------------- style for the home page main banner */

/* style for the services section  -------------start */
.services-section {
  display: flex;
  flex-wrap: wrap;
}
.services-left {
  flex: 1;
  padding-right: 100px;
  color: #fff;
}
.services-right {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.services-left p {
  font-size: 1rem;
  line-height: 1.5;
}

.service-card {
  background: #111a2e;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* flex: 1 1 calc(33.333% - 20px); */
  flex-basis: calc(33.333% - 20px);
  box-sizing: border-box;
  text-align: center;
  height: auto;
  transition: all 0.3s ease 0.2s;
}

.service-card:hover {
  cursor: pointer;
  background: linear-gradient(45deg, #1a1f36, #262d4f, #2d3662); 
  transform: translateY(-5px);
}

.service-card a{
  text-decoration: none;
  color: #fff;
}

.service-card i {
  font-size: 2.5rem;
  margin: 2rem 0;
  color: white; 
  border-radius: 50%;
  padding: .05rem; 
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  height: 4.5rem;
}
.serivec-img-div {
    margin: 2rem 0;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
}
.serivec-img-div img{
  width: 30px;
  height: 30px;
  filter: invert(1);
}
.service-card h3 {
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: 500;
}

/* Card style starts here -------------start */
.card {
  border-radius: 20px;
  box-sizing: border-box;
  color: #fff;
  text-align: left;
  height: auto;
  transition: all 0.3s ease 0.2s; 
  margin: 2rem 0;
}

/* Feature section starts here -------------start */

.feature-list {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.feature-left h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.feature-left p {
  font-size: 1rem;
  line-height: 1.5;
}

.feature-card {
  background: linear-gradient(to bottom, #111a2e 0%, rgba(18, 27, 48, 0) 100%);
  flex: 1 1 calc(50% - 20px); /* Adjusted width */
  margin: 2rem 0;
  padding: 2rem 4rem;
}

.card ul {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}

.card li {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 50px;
  margin: 10px 0; 
  transition: background 0.3s ease, all 0.3s ease 0.2s; 
  background: linear-gradient(90deg, #111a2e, #262d4d);
}

.card li i {
  display: content;
  font-size: 0;
  margin-right: 10px;
  color: #6666ff;
  border-radius: 50%;
  padding: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
}

.card li:hover {
  background: linear-gradient(90deg, #1a1f36, #262d4d);
}

.card li:hover i{
  font-size: 1.5rem;
}

/* Image Description Section style starts here -------------start */
.image-description-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.image-description-right {
  flex: 1; 
  padding-left: 10px; 
}

.image-description-right {
  padding-left: 20px; 
}

.image-description-left img {
  width: 100%; 
}


/* FORM */


/* Contact Container */
.contact-container {
  display: flex;
  border-radius: 10px;
  border: 1px solid #2a184b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
}

/* Contact Form */
.contact-form {
  flex: 1;
  padding: 1rem;
  box-sizing: border-box;
}

.contact-form h2 {
  margin-bottom: 20px;
  color: #333;
}

.contact-form .form-group {
  margin-bottom: 10px;
}

/* Contact Form Input elements/ */
.contact-form label {
  display: block;
  margin-bottom: 5px;
  color: #c1c1c1;
}

.form-group textarea {
  border: 0;
  background: transparent;
  display: block;
  width: 100%;
  min-height: 50px;
  padding: 11px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  color: #666699;
  background-color: transparent;
  background-image: none;
  border-radius: 0;
  -webkit-appearance: none;
  transition: .3s ease-in-out;
  border: 2px solid transparent;
  border-bottom-color: #666699;
}

.form-group input {
  border: 0;
  background: transparent;
  display: block;
  width: 100%;
  min-height: 50px;
  padding: 11px 0;
  font-weight: 600;
  line-height: 27px;
  color: #666699;
  background-color: transparent;
  background-image: none;
  border-radius: 0;
  -webkit-appearance: none;
  transition: .3s ease-in-out;
  border: 2px solid transparent;
  border-bottom-color: #666699;
}

.form-group input::placeholder {
color:#666699;
}
.form-group textarea::placeholder {
color:#666699;

}
.form-group input {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 600;
  height: 55px;
  
}
.form-group input:hover, .contact-form input:focus{
  outline: none;
  box-shadow: none;
  background: transparent;
  border: 2px solid transparent;
  border-bottom-color: rgb(254, 132, 111);
}

.form-group textarea:hover, .form-group textarea:focus{
background: transparent; 
  outline: none;
box-shadow: none;
   border: 2px solid transparent;
  border-bottom-color: rgb(254, 132, 111);

}

.contact-button {
  width: 50%;
  min-width: 200px;
  padding: 15px;
  margin-top: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #6699ff;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.contact-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.15);
  transform: translate(-50%, -50%) rotate(45deg);
  transition: width 0.3s ease, height 0.3s ease;
  border-radius: 50%;
  z-index: -1;
}

.contact-button:hover::before {
  width: 100%;
  height: 100%;
}

.contact-button:hover {
  background-color: #6666b3;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

/* Contact page Details */

.contact-details {
  flex: 1;
  padding: 20px;
  color: #fff;
  box-sizing: border-box;
}

.contact-details p {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.office p{
  line-height: 40px;
}

.contact-details i {
  margin-right: 20px;
  line-height: 40px;
  font-size: 30px;
}

.contact-details .map img {
  width: 100%;
  border-radius: 5px;
}

.contact-card {
  flex: 1 1 calc(33.3% - 20px); 
  box-sizing: border-box;
  margin: 2rem 0;
  border: 1px solid #2a184b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem 2rem;
}

/* Process timeline -------------start */

.Process_processContainer {
  padding: 0;
  position: relative;
}

.Process_sectionHeading {
  color: #6d6d6d;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  line-height: 18px;
  padding-left: 90px;
  margin-top: 2rem;
}

.Process_sectionHeading:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 65px;
  background: #6d6d6d;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.Process_processWrapContainer {
  position: relative;
  padding-left: 30px;
}

.Process_processWrapContainer:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 103%;
  left: 0;
  top: 0;
  background: #ffffff3e;
  opacity: 0;
}

#wrapId {
  display: flex;
  flex-direction: column;
}

.Process_processWrap h5 {
  color: #6d6d6d;
  position: absolute;
  left: -30px;
  top: 30px;
  font-weight: 400;
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .Process_processWrap h5 {
      top: 55px;
  }
}

@media only screen and (min-width: 992px) {
  .Process_processContainer {
      padding: 100px 0;
      padding-bottom: 0;
  }
  .Process_processWrapContainer:before {
    height: 100%;
    opacity: 1;
  }

  .Process_processWrap h5 {
    left: -60px;
    top: 60px;
  }
}

@media only screen and (min-width: 768px) {
  .Process_processWrap {
      padding: 90px 0;
  }
}

.Process_processWrap h3 {
  font-weight: 400;
  line-height: 100%;
  margin-top: 0;
  margin-bottom: 15px;
  color: #6d6d6d;
  transition: all 0.3s ease;
}

.Process_processWrap h3:before {
  content: attr(data-count);
  line-height: 18px;
  color: #e4e3e4;
  text-transform: uppercase;
  position: absolute;
  left: -40px;
  top: 0;
}

.Process_processWrap p {
  line-height: 150%;
  max-width: 100%;
  color: #6d6d6d;
  opacity: 1;
  margin: 0;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) {
  .Process_processWrap p {
      max-width: 244px;
  }
  .Process_processWrap .list{
    max-width: 400px;
  }
}

.Process_processWrap {
  padding: 0;
  position: relative;
  padding-bottom: 100px;
}

.Process_processCotentList {
  position: absolute;
  top: 0;
  right: 0;
  min-height: 150px;
  height: auto;
  max-width: 700px;
  width: 100%;
  margin: 0;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.Process_boxContent ul {
  transition: all 0.35s ease;
  animation: Process_fadeIn 1s linear;
  margin-top: 50px;
}

.Process_boxContent ul:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  bottom: -25px;
  height: auto;
  background: transparent linear-gradient(165deg, #6699ff, #ffffff00 50%) 0 0 no-repeat padding-box; 
  opacity: 0.25;
}

.Process_boxContent ul li {
  display: inline-block;
  width: 100%;
  text-align: left;
  opacity: 0.5;
  line-height: 10px;
  position: relative;
  padding-left: 20px;
}

.Process_boxContent ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  display: block;
}


.Process_scrollElement {
  position: absolute;
  content: "";
  width: 3px;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 999;
  opacity: 1;
  transform: translateY(0);
}

.pin-spacer {
  position: absolute;
  top: 0;
  order: 0;
  z-index: 999;
  float: none;
  flex-shrink: 1;
  display: block;
  width: 3px;
  height: 100px;
  padding: 0;
  left: 0;
}

.Process_processWrap.isScrollView.is-inview h3,
.Process_processWrap.isScrollView.is-inview h5,
.Process_processWrap.isScrollView.is-inview p {
  color: #fff !important;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}



/* Footer styles -------------start */

#footer {
  padding: 2rem 0 0;
  color: #c1c1c1;;
}
main{
  overflow: hidden;
}
.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
  border-top: 1px solid #2e3f6a;
}

.footer-logo .logo {
  max-width: 150px;
}

.footer-links {
  display: flex;
  gap: 40px;
  line-height: 40px;
}

.footer-links .links-column {
  display: flex;
  flex-direction: column;
}

#footer a {
  text-decoration: none;
  color: #c1c1c1;
}

#footer a:hover{
  color: #66b3ff;
}
.footer-social {
  margin-top: 3rem;
}

.footer-subscribe {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.footer-subscribe h4{
  margin-top: 0;
}

.footer-subscribe p {
  margin-bottom: 10px;
}

.footer-subscribe form {
  display: flex;
  justify-content: space-between;
}

.footer-subscribe input {
  border: 0;
  background: transparent;
  display: block;
  min-height: 30px;
  width:auto;
  padding: 11px 5px;
  font-weight: 600;
  line-height: 27px;
  color: #666699;
  background-color: transparent;
  background-image: none;
  border-radius: 0;
  -webkit-appearance: none;
  transition: .3s ease-in-out;
  border: 2px solid transparent;
  border-bottom-color: #666699;
}

.footer-subscribe input::placeholder {
color:#666699;
}

.footer-subscribe textarea::placeholder {
color:#666699;
}

input:focus{
  outline: none;
  box-shadow: none;
  background: transparent;
  border: 2px solid transparent;
  border-bottom-color: rgb(254, 132, 111);

}

.footer-subscribe button {
  padding: 10px 20px;
  margin-top: 1rem;
  text-align: center;
  border: none;
  background-color: #6699ff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}


.footer-subscribe button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.15);
  transform: translate(-50%, -50%) rotate(45deg);
  transition: width 0.3s ease, height 0.3s ease;
  border-radius: 50%;
  z-index: -1;
}

.footer-subscribe button:hover::before {
  width: 100%;
  height: 100%;
}

.footer-subscribe button:hover {
  background-color: #6666b3;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}


.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding: 2rem 0;
  border-top: 1px solid #2e3f6a;
  max-width: 1200px;
  border-radius: 30px 30px 0 0;
  background: linear-gradient(45deg, #3a6fa3, #27498d, #2d2d91, #444495, #3c3c7e, #225485);
  margin: 0 auto;
}


.footer-bottom p {
  margin: 0 1rem;
}

.footer-bottom a {
  margin: 0 1rem;
}


/*Team member section -------------start */

.team-section {
  text-align: center;
  padding: 40px 0px;
}

.team-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.team-carousel .team-member {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  margin: 1rem 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease, box-shadow 0.4s ease, opacity 0.4s ease;
  margin-bottom: 0;
  border-radius: 20px 20px 0 0;
}

.team-carousel .team-member:hover {
  animation: bounceIn 0.6s forwards;
  opacity: 0.9;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.team-carousel .member-image {
  max-height: 300px;
}

.team-carousel .member-image img {
  /* width:auto; */
  border-radius: 10px 10px 0 0;
}

.team-carousel .social-icons {
  position: absolute;
  bottom: 33%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  font-size: 1.25rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.team-carousel .social-icons a {
  color: #fff;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.8);
  padding: 15px;
  border-radius: 50%;
  transition: background 0.3s ease;
}

.team-carousel .social-icons a:hover {
    background-color:#007bff;   
}

.team-carousel .team-member:hover .social-icons {
  opacity: 1;
}

.team-member .member-info {
  background-color: #0000006b;
  color: #fff;
  padding: 1rem;
  position: relative;
  min-height: 100px;
  z-index: 1;
  transition: background-color 0.6s ease, color 0.6s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team-member:hover .member-info {
  background-color: rgb(0, 123, 255, 0.5);
  color: #fff;
  border-radius: 0 0 10px 10px;
}


.team-carousel .member-info h3 {
  margin: 0;
  font-size: 1.2rem;
}

.team-carousel .member-info p {
  margin: 5px 0 0;
}

.team-carousel .owl-dots {
  text-align: center;
  margin-top: 20px;
}

.team-carousel .owl-dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 5px;
  background-color: #ddd !important;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.team-carousel .owl-dot.active {
  background-color: #007bff !important;
}


/* Testimonial section -------------start */

.testimonial-section {
  padding: 60px 0;
}

.testimonial-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.testimonial-item {
  text-align: center;
  margin: 5rem 0 0;
  padding: 2rem;
  border-radius: 10px;
  border:1px solid #102a48;
  background: rgb(45, 45, 145, 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.testimonial-item:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.testimonial-item .icon {
  font-size: 40px;
  color: #007bff;
  margin-bottom: 20px;
}

.testimonial-item .profile-img {
  width: 100px !important;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 20px;
  border: 4px solid #fff;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.testimonial-item p {
  font-size: 16px;
  color: #c1c1c1;
  margin-bottom: 20px;
  line-height: 1.6;
}

.testimonial-item .name {
  font-size: 18px;
  font-weight: bold;
  color: #c1c1c1;
  margin-bottom: 5px;
}

.testimonial-item .position {
  font-size: 14px;
  color: #999;
}

.owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 5px;
  background-color: #ddd;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.owl-dot.active {
  background-color: #007bff;
}


.booking-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Multi screen booking form -------------start */
.prev {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  display: none;
}

.prev:hover {
  color: #D50000;
}

.text-center {
  text-align: center;
}

.progress {
  margin: 20px 0;
  text-align: center;
}

#progressbar {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
}

#progressbar li {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border: 2px solid #E53935;
  border-radius: 50%;
  background: #fff;
  font-size: 12px;
  font-family: 'FontAwesome';
  color: transparent;
}

#progressbar li.active {
  background: #E53935;
  color: #fff;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-step {
  display: none;
  width: 100%;
}

.form-step.show {
  display: block;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #BDBDBD;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

input:focus,
select:focus {
  border-color: #E53935;
  outline: none;
}

.next-button {
  width: 100%;
  padding: 10px;
  background-color: #BDBDBD;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.next-button:hover {
  background-color: #E53935;
}

.success-message {
  padding: 20px;
}

.tick {
  width: 100px;
  height: 100px;
}

@media (max-width: 600px) {
  .container {
    padding: 10px;
  }


  .prev {
    top: -40px;
  }

  .next-button {
    padding: 8px;
  }

  .tick {
    width: 80px;
    height: 80px;
  }
}

.image-description-left{
  width: 30%;
}
.header-banner .item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.section-desciption{
  padding-left: 15px;
  padding-right: 15px;
}
.owl-carousel.owl-loaded{
  display: block !important;
}
.testimonial-container  .owl-nav{
  display: none;
}
.testimonial-container .owl-theme .owl-dots .owl-dot span,.team-carousel.owl-theme .owl-dots .owl-dot span{
  display: none;
}
.header-banner .content p {
  font-size: 1.9rem;
  font-weight: 400;
}
.member-description{
  border-radius: 0 0 20px 20px;
  background: #fff;
  padding: 20px;
  height: 670px;
}
img.image_description{
  filter: invert(1);
  width: 150px;
  margin: auto;
  display: block;
}
.para_list{
  position: relative;
  padding-left: 20px;
}
.para_list::after{
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  left: 0;
  top: 10px;
}
.about-meet-session{
  padding-bottom: 0;
}
.content-right{
  width: 50%;
  left: 100%;
  transform: translate(-100%, -50%);
  padding-right: 30px;
}
.content-left{
  width: 50%;
  left: 0;
  transform: translate(0, -50%);
  padding-left: 30px;
}
.about-main .get-section{
  padding: 0;
}
#header-slider .item.h-100vh{
  height: 100vh;
}
.pl-2
{
  padding-left: 0.5rem;
}
.footer-bottom a.m-0{
  margin-right:1px;
  margin:0px 5px;
}
.mt-1{
  padding-top:10px;
}
.footer-xilligence{
  display: flex;
}
.logo-footer{
  object-fit: contain;
}
.text-bold{
  font-weight: 600;
}
.text-danger{
  color:red;
}
.client-logos{
  display: flex;
  flex-wrap:wrap;
  gap:20px;
  justify-content: center;
}
.logos{
  padding: 10px;
  border-radius: 5px;
}
.logos .img-fluid{
  /* border-radius: 5px; */
  border-radius: 15px;
}
.team-member-data{
  /* width: 365px; */
  width:289px;
  /* margin: 0 auto; */
}
.member-image img{
  width:100%;
}
.member-data{
  display: flex;
  flex-wrap: wrap;
  gap:13px;
  justify-content: center;
}
.team-carousel .owl-stage{
  display: flex;
  flex-wrap: wrap;
  width:100% !important;
}
.terms-wrapper p, .terms-wrapper h1, .terms-wrapper h2, .terms-wrapper ul{
  color: #c1c1c1;
}
.terms-wrapper h1 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 0;
}
.terms-wrapper h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 20px;
}
.terms-wrapper a{
  color: #66b3ff;
}
.terms-wrapper li {
  margin-bottom: 10px;
}