  /* style for the mobile screens */

  @media (max-width: 1200px) {
    .bouncing-blobs-glass {
      backdrop-filter: blur(120px);
      -webkit-backdrop-filter: blur(120px);
    }

    .content-right {
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
    }

    #header-slider .item {
      height: 100vh;
    }

    .member-description {
      min-height: 420px;
    }
  }

  @media (max-width:1140px) {
    .member-description {
      min-height: 470px;
    }
  }

  @media (max-width:1080px) {
    .team-member-data {
      width: 47%;
      margin: 0 auto;
    }

    .member-description {
      min-height: 440px;
    }
  }

  @media (max-width:991px) {
    .footer-bottom .xilli-logo-footer {
      display: flex;
    }

    .footer-xilligence {
      justify-content: center;
      align-items: center;
    }

    .services-left {
      width: 100%;
      flex: auto;
      padding-right: 0;
    }

    .services-section {
      padding-left: 15px;
      padding-right: 15px;
    }

    .image-description-left {
      width: 100%;
      margin-top: 20px;
    }

    .image-description-section {
      flex-direction: column-reverse;
    }

    .member-description {
      min-height: 330px
    }
  }

  @media(max-width: 930px) {
    .member-description {
      min-height: 370px;
    }
  }

  @media(max-width: 850px) {
    .member-description {
      min-height: 390px;
    }

    .menu-item a,
    .menu-item span {
      padding: 15px 5px;
    }
  }

  @media(max-width: 800px) {
    .member-description {
      height: 600px;
    }

    .team-member-data {
      width: 320px;
    }
  }

  @media(max-width: 767px) {
    .member-description {
      height: auto;
      min-height: 200px;
    }

    .team-carousel .member-image {
      max-height: unset;
    }

    .terms-wrapper p,
    .terms-wrapper ul {
      font-size: 16px;
    }

    .terms-wrapper ul {
      padding-left: 20px;
    }

    .terms-wrapper h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media (max-width: 500px) {
    .xilli-logo-footer {
      display: block;
    }

    .footer-bottom p.footer-xilligence {
      display: block;
      font-size: 14px;
    }

    .footer-bottom .xilli-logo-footer {
      display: block;
    }

    .logo-footer {
      margin-top: 3px;
    }

    #header-slider .item.h-100vh {
      height: 1030px;
    }

    .bouncing-blob {
      width: 60vw;
    }

    .bouncing-blob--white {
      width: 30vw;
    }

    .bouncing-blobs-glass {
      backdrop-filter: blur(90px);
      -webkit-backdrop-filter: blur(90px);
    }
  }

  @media (max-width: 768px) {
    .logo img {
      height: 25px;
    }

    .section {
      padding: 2rem 2rem;
    }

    h2 {
      line-height: 2.8rem;
    }

    /* style for the header area including the logo and menu starts here*/
    .navbar {
      overflow-y: auto;
    }

    .logo h2 {
      color: #000;
    }

    .effect a {
      color: #000;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: #fff;
      transition: left 0.3s;
      justify-content: center;
      align-items: center;
    }

    .nav-menu.active {
      left: 0;
    }

    .nav-menu li {
      text-align: left;
      margin: 0;
    }

    .menu {
      /* display: flex;
      overflow-y: auto;
      flex-direction: column;
      width: 80%;
      height: 70vh;
      justify-content: center; */
      overflow-y: auto;
      width: 80%;
      height: calc(100% - 100px);
      margin-top: 100px;
      display: unset;
      width: calc(100% - 2rem);
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .menu-toggle {
      display: flex;
    }

    .mobile-logo {
      display: block;
      position: absolute;
      top: 2rem;
      left: 2rem;
    }

    .mobile-social {
      display: contents;
    }

    .social {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1rem 3rem;
    }

    .submenu {
      position: relative;
      top: auto;
      left: auto;
      display: none;
      overflow-y: auto;
    }

    .menu-item.active .submenu {
      display: block;
    }

    .nav-menu .submenu {
      display: none;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    .nav-menu .has-submenu.active .submenu {
      display: block;
    }

    /* .menu-item:hover .submenu{
    position: absolute;
  } */

    /* End-------------------------- style for the header area including the logo and menu */

    .rotate {
      top: 20px;
    }

    .content {
      width: 80%;
    }

    .content h1 {
      font-size: 38px;
      line-height: 1.4;
    }

    .header-banner .content p {
      font-size: 26px;
    }

    h2 {
      font-size: 34px;
    }

    .content p {
      font-size: 1rem;
      margin: 1rem 0 0;
    }

    .long-oval {
      margin: 2rem 0 1rem 0;
    }

    .long-trans {
      margin: 0 0 1rem 0;
    }

    .title {
      padding: 5px 20px;
    }

    .services-section {
      flex-direction: column;
    }

    .services-left {
      width: 100%;
      padding: 1rem 0;
    }

    /* 
  .services-right {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding: 20px 0;
  } */
    .services-right {
      flex-wrap: wrap;
    }

    .service-card {
      flex: 0 0 100%;
      margin-right: 5px;
      flex-direction: column;
    }

    .feature-card {
      padding: 2rem 1rem;
      margin: 0;
    }

    .feature-card ul {
      margin: 2rem 0;
    }

    .feature-list {
      flex-direction: column;
      display: flex;

    }

    /* section */
    .image-description-section {
      gap: 2rem;
      padding: 2rem;
      flex-direction: column;
    }

    .image-description-right {
      padding-left: 0;
      padding: 0;
    }

    /* section */
    .contact-container {
      flex-direction: column;
    }

    .contact-form {
      width: 100%;
    }


    .contact-details .detail-item {
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;
    }

    .contact-details strong {
      margin-right: 5px;
    }

    .contact-details span {
      flex: 1;
    }


    /* Height of the scrollbar */
    ::-webkit-scrollbar {
      height: 0
    }



    .Process_boxContent ul:before {
      top: -20px;
    }

    .Process_boxContent ul li {
      width: 80%;
    }

    .Process_processCotentList {
      position: relative;
      top: 10px;
      right: auto;
      height: auto;
      max-width: 100%;
      transform: none;
      margin-top: 20px;
      min-height: auto;
    }

    .Process_processContainer {
      margin-top: 6rem;
    }

    .Process_sectionHeading {
      top: -6rem;
    }

    .Process_processWrapContainer {
      padding-left: 10px;
    }

    .footer-container {
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }


    .footer-links {
      flex-direction: row;
      gap: 10px;
      width: 90%;
    }

    .footer-logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      overflow: hidden;
    }

    .footer-logo img {
      width: 100px;
    }

    .footer-links .links-column {
      flex: 0 0 50%;
      align-items: flex-start;
    }

    .social {
      position: static !important;
    }

    .footer-subscribe form {
      flex-direction: column;
    }

    .footer-subscribe input {
      width: 100%;
    }

    .footer-subscribe button {
      width: 100%;
    }

    .footer-subscribe h4 {
      margin-top: 2rem;
    }

    .footer-bottom {
      line-height: 40px;
      display: block;
      padding: 20px 10px;
    }

    .section-desciption h2 {
      font-size: 34px;
    }

    .submenu.show {
      display: block;
      visibility: visible;
      opacity: 1;
      left: 0;
      margin-top: 30px;
      pointer-events: auto;
    }

    .uparrow::after {
      content: "\25B2" !important;
    }

    .footer-bottom p {
      font-size: 12px;
      margin: 0;
    }

    main {
      margin-top: 85px;
    }

    .menu-item a,
    .menu-item span {
      padding: 15px;
    }

    .terms-wrapper {
      padding: 1rem;
    }
  }


  @media (max-width: 480px) {
    .footer-links {
      flex-direction: row;
      justify-content: left;
      gap: 10px;
      width: 90%;
    }

    .footer-links .links-column {
      flex: 0 0 50%;
      align-items: flex-start;
      padding-left: 45px;
    }

    .footer-subscribe form {
      flex-direction: column;
      align-items: center;
    }

    .footer-subscribe input,
    .footer-subscribe button {
      width: 100%;
    }
  }

  @media(max-width:414px) {
    .footer-links .links-column {
      padding-left: 50px;
    }
  }

  @media(max-width:375px) {
    .footer-links .links-column {
      padding-left: 35px;
    }
  }

  @media(max-width:360px) {
    .footer-links .links-column {
      padding-left: 30px;
    }
  }

  @media(min-width: 769px) {
    .menu-item:hover .submenu {
      display: block;
      visibility: visible;
      opacity: 1;
      left: 0;
    }

    .menu-item:hover .submenu {
      pointer-events: all;
      transform: translateY(0);
    }
  }